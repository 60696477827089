<template>
  <y-button
    v-if="isLocked"
    :label="lockedLabel"
    :title="lockedTitle"
    :icon="lockedIcon"
    :disabled="lockedDisabled"
    widthType="auto"
  />
  <y-button
    v-else-if="canAdd"
    label="Add"
    title="Add this session to your calendar"
    icon="plus"
    widthType="auto"
    @click="addPanelSession"
    :disabled="isLoading"
  />
  <y-button
    v-else-if="!props.panelSession.isAdded && props.panelSession.attendingPanel"
    label="Switch Time"
    title="Swap panel session to this time"
    icon="calendar"
    widthType="auto"
    @click="switchPanelSession"
    :disabled="isLoading"
  />
  <y-button
    v-else-if="props.panelSession.isAdded"
    label="Remove"
    title="Remove this session from your calendar"
    icon="trash-solid"
    background-color="primary"
    widthType="auto"
    @click="removePanelSession"
    :disabled="isLoading"
  />
</template>
<script setup lang="ts">
import type { PanelSession } from '@/types/PanelSession'

import { ref, computed } from 'vue'
import { YButton } from '@yakimachief/ych-ts-component-library'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import { useOrganizationStore } from '@/stores/OrganizationStore'

const panelSessionStore = usePanelSessionStore()
const organizationStore = useOrganizationStore()

const props = defineProps<{
  panelSession: PanelSession
}>()

const isLoading = ref(false)

const addPanelSession = function () {
  if (isLoading.value) return
  isLoading.value = true
  panelSessionStore.addPanelSession(props.panelSession.id).then((response) => {
    isLoading.value = false
  })
}
const removePanelSession = function () {
  if (isLoading.value) return
  isLoading.value = true
  panelSessionStore.removePanelSession(props.panelSession.id).then(() => {
    isLoading.value = false
  })
}
const switchPanelSession = function () {
  if (isLoading.value) return
  isLoading.value = true
  panelSessionStore.switchPanelSession(props.panelSession.id).then(() => {
    isLoading.value = false
  })
}
const canAdd = computed(() => {
  return !props.panelSession.isAdded && !props.panelSession.attendingPanel
})
const addDisabled = computed(() => {
  return isLoading.value
})
const isLocked = computed(() => {
  return (
    props.panelSession.changeWindowClosed ||
    props.panelSession.isFull ||
    props.panelSession.panelProgress > 0 ||
    props.panelSession.overlappingSessions > 0 ||
    (props.panelSession.atMaxTakeAwaySessions &&
      props.panelSession.isTakeAway &&
      !props.panelSession.attendingPanel)
  )
})
const lockedLabel = computed(() => {
  if (props.panelSession.isAdded && props.panelSession.panelProgress > 0) return 'In Progress'
  if (props.panelSession.panelProgress > 0) return 'Panel Started'
  if (props.panelSession.isAdded) return 'Attending'
  if (props.panelSession.isFull) return 'Full'
  if (props.panelSession.changeWindowClosed) return 'Locked'
  if (props.panelSession.overlappingSessions > 0) return 'Conflict'
  if (
    props.panelSession.atMaxTakeAwaySessions &&
    props.panelSession.isTakeAway &&
    !props.panelSession.attendingPanel
  )
    return 'Max Panels'
  return 'Unknown'
})
const lockedTitle = computed(() => {
  if (props.panelSession.isAdded) return 'Attending'
  if (props.panelSession.isFull) return 'This session is full.'
  if (props.panelSession.overlappingSessions > 0)
    return 'Already attending a session during this time.'
  if (
    props.panelSession.atMaxTakeAwaySessions &&
    props.panelSession.isTakeAway &&
    !props.panelSession.attendingPanel
  )
    return 'Already attending the max  take-away sessions for this day.'
  return 'Contact admin to get added to upcoming session.'
})
const lockedIcon = computed(() => {
  if (props.panelSession.isAdded) return 'check'
  if (props.panelSession.panelProgress > 0) return undefined
  if (props.panelSession.changeWindowClosed) return 'lock'
  if (props.panelSession.overlappingSessions > 0) return 'triangle-exclamation'
  if (
    props.panelSession.atMaxTakeAwaySessions &&
    props.panelSession.isTakeAway &&
    !props.panelSession.attendingPanel
  )
    return 'triangle-exclamation'
  return 'triangle-exclamation-solid'
})
const lockedDisabled = computed(() => {
  if (props.panelSession.isAdded) return false
  if (props.panelSession.panelProgress > 0) return true
  if (props.panelSession.overlappingSessions > 0) return true
  if (
    props.panelSession.atMaxTakeAwaySessions &&
    props.panelSession.isTakeAway &&
    !props.panelSession.attendingPanel
  )
    return true
  return props.panelSession.changeWindowClosed
})
</script>
<style scoped lang="scss"></style>
