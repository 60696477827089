import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import type { Moment } from 'moment'
import moment from 'moment/moment'
import { ref } from 'vue'

export const useLoadingStore = defineStore('LoadingStore', {
  state: () => ({
    initialFetchTime: null as Moment | null,
    forceUpdate: ref(false),
    forceUpdateTimeout: 3000
  }),
  actions: {
    setInitialFetchTime() {
      this.initialFetchTime = moment()
      setTimeout(() => {
        this.forceUpdate = !this.forceUpdate
      }, this.forceUpdateTimeout)
    }
  },
  getters: {
    isLoading: (state) => {
      const userStore = useUserStore()
      const panelSessionStore = usePanelSessionStore()

      const loading =
        userStore.loaded.userFetching ||
        userStore.loaded.notesFetching ||
        userStore.loaded.referenceStandardsFetching ||
        userStore.loaded.metricsFetching ||
        panelSessionStore.loaded.availableFetching ||
        panelSessionStore.loaded.scheduledFetching

      if (state.initialFetchTime) {
        return loading && !state.forceUpdate
      }

      return loading
    },
    isLoadingCalendar: (state) => {
      const panelSessionStore = usePanelSessionStore()
      return (
        panelSessionStore.loaded.scheduledReFetching || panelSessionStore.loaded.scheduledFetching
      )
    },
    isLoadingAvailableSessions: (state) => {
      const panelSessionStore = usePanelSessionStore()
      return (
        panelSessionStore.loaded.availableReFetching || panelSessionStore.loaded.availableFetching
      )
    }
  }
})
