<template>
  <div class="panel-item">
    <div class="panel-item__top-row" @click="isOpen = !isOpen">
      <div class="panel-item__top-row__left">
        <div
          class="panel-item__top-row__left__giant-check"
          :title="
            isAdded
              ? 'You are registered for one of this panel\'s sessions.'
              : 'You are not registered for any of this panel\'s sessions.'
          "
        >
          <y-icon icon="check" class="check-icon" font-size="1.25rem" v-if="isAdded" />
        </div>
        <div class="panel-item__top-row__left__name-date-col">
          <h3 class="panel-item__top-row__left__name-date-col__name">
            {{ panelName }}
            <y-icon
              icon="right-from-bracket-solid"
              v-if="isTakeAway"
              font-size="1rem"
              title="This is a take-away session"
            />
          </h3>
          <h4 class="panel-item__top-row__left__name-date-col__date">{{ date }}</h4>
        </div>
      </div>
      <div class="panel-item__top-row__right">
        <div
          v-if="panel.panelSessions?.length !== 0"
          class="panel-item__top-row__right__sample-icons flex gap-2 items-center"
        >
          <div>Samples:</div>
          <div class="flex gap-1 items-center">
            <y-icon
              icon="beer-mug"
              v-if="hasBeer"
              font-size="1rem"
              title="This session includes beer samples"
            />
            <i
              class="fa-kit fa-variety-cone"
              v-if="hasHops"
              title="This session includes hops samples"
            ></i>
            <y-icon
              icon="circle-question-solid"
              v-if="hasOther"
              font-size="1rem"
              :title="'This session includes ' + otherType.toLowerCase() + ' samples'"
            />
          </div>
        </div>
        <div class="panel-item__top-row__right__expand-collapse">
          <y-icon
            icon="chevron-right"
            font-size="1rem"
            :title="isOpen ? 'Expand to show sessions' : 'Collapse to hide sessions'"
            :class="chevronClasses"
          />
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="panel-item__session-list" v-if="isOpen">
        <div
          class="panel-item__session-list__items"
          v-if="panel.panelSessions && panel.panelSessions.length > 0"
        >
          <GroupByPanelSessionItem
            v-for="panelSession in panel.panelSessions"
            :panel-session="panelSession"
            :key="'panel-session-' + panelSession.id"
          />
        </div>
        <div v-else>
          <p class="alert alert-info">There are no sessions scheduled for this panel yet.</p>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import moment from 'moment'

import { YIcon } from '@yakimachief/ych-ts-component-library'
import useToasterStore from '@/stores/ToasterStore'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import type { Panel } from '@/types/Panel'
import GroupByPanelSessionItem from '@/components/UpcomingSessions/GroupByPanelSessionItem.vue'

const panelSessionStore = usePanelSessionStore()

const props = defineProps<{
  panel: Panel
}>()

/**
 * Component State
 */

const isLoading = ref(false)
const isOpen = ref(false)

/**
 * Computed Values
 */
const panelName = computed(() => {
  return props.panel.name
})

// @todo: make date range instead of single date. i.e, Dec 4-5, 2023
const date = computed(() => {
  if (!props.panel.panelSessions) return false
  return moment(props.panel.panelSessions[0].startTime).format('MMM D, YYYY')
})
const isTakeAway = computed(() => {
  return props.panel.isTakeAway
})

// @todo: mock data has all samples displaying as other for some reason
const hasBeer = computed(() => {
  if (!props.panel.panelSessions) return false
  return props.panel.panelSessions[0].types.includes('beer')
})
const hasHops = computed(() => {
  if (!props.panel.panelSessions) return false
  return props.panel.panelSessions[0].types.includes('hops')
})
const hasOther = computed(() => {
  return otherTypes.value.length > 0
})
const otherTypes = computed(() => {
  if (!props.panel.panelSessions) return []
  return props.panel.panelSessions[0].types.filter((type) => {
    return !(type === 'hops' || type === 'beer')
  })
})

const otherType = computed(() => {
  return otherTypes.value.join(', ')
})

const chevronClasses = computed(() => ({
  'panel-item__top-row__right__expand-collapse__chevron': true,
  'panel-item__top-row__right__expand-collapse__chevron--rotated': isOpen.value
}))

const isAdmin = ref(false)

// @todo: I don't think this is working yet
const isAdded = computed(() => {
  if (!props.panel.panelSessions) return false
  const panelSession = props.panel.panelSessions.find((panelSession) => panelSession.isAdded)
  return panelSession?.isAdded
})

const toasterStore = useToasterStore()
</script>
<style scoped lang="scss">
.panel-item {
  border-radius: var(--y-radius-md);
  box-shadow: var(--y-shadow-grey-sm);
  &__top-row {
    position: relative;
    background: var(--ych-grey-100);
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    color: var(--secondary-brand-color);
    z-index: 2;
    cursor: pointer;
    &__left {
      display: flex;
      gap: 1rem;
      align-items: center;
      &__giant-check {
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid var(--secondary-brand-color);
        border-radius: var(--y-radius-md);
        flex-shrink: 0;
      }
      &__name-date-col {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        &__name {
        }
        &__date {
          color: var(--ych-grey-400);
        }
      }
    }
    &__right {
      display: flex;
      gap: 1rem;
      align-items: center;
      &__sample-icons {
      }
      &__expand-collapse {
        &__chevron {
          transition: transform 0.3s ease-out;
          &--rotated {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__session-list {
    z-index: 1;
    border-top: 1px solid var(--ych-grey-200);
    &__items {
      background: var(--ych-white);
    }
  }
}

.slide-fade-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.slide-fade-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-2rem);
  opacity: 0;
}
</style>
