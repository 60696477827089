import { defineStore } from 'pinia'
import type { PanelSession } from '@/types/PanelSession'
import axios from 'axios'
import router from '@/router'
import useToasterStore, { errorToast, successToast, quickToast } from '@/stores/ToasterStore'
import moment, { type Moment } from 'moment/moment'
import type { Panel } from '@/types/Panel'
import { end } from '@popperjs/core'
import { watch } from 'vue'

type PanelSessionStoreLoadingStatus = {
  available: boolean
  availableFetching: boolean
  availableReFetching: boolean
  scheduled: boolean
  scheduledFetching: boolean
  scheduledReFetching: boolean
  history: boolean
  historyFetching: boolean
}

type PanelSessionStoreData = {
  availablePanelSessions: PanelSession[]
  loadedScheduledPanelSessions: PanelSession[]
  loadedScheduledPanelSessionRange: {
    earliest: Moment
    latest: Moment
  } | null
  panelSessionsHistory: PanelSession[]
  loaded: PanelSessionStoreLoadingStatus
  panelSessionIcalFeed: string
}

const castDatesFromApi = function (apiPanelSessionData: any): PanelSession {
  const newPanelSession = apiPanelSessionData
  newPanelSession.startTime = new Date(newPanelSession.startTime)
  newPanelSession.endTime = new Date(newPanelSession.endTime)
  newPanelSession.createdAt = new Date(newPanelSession.createdAt)
  newPanelSession.modifiedAt = new Date(newPanelSession.modifiedAt)
  return newPanelSession
}

const usePanelSessionStore = defineStore('PanelSessionsStore', {
  state: (): PanelSessionStoreData => {
    return {
      availablePanelSessions: [],
      loadedScheduledPanelSessions: [],
      loadedScheduledPanelSessionRange: null,
      panelSessionsHistory: [],
      panelSessionIcalFeed: '',
      loaded: {
        available: false,
        availableFetching: false,
        availableReFetching: false,
        scheduled: false,
        scheduledFetching: false,
        scheduledReFetching: false,
        history: false,
        historyFetching: false
      }
    }
  },
  actions: {
    async fetchAvailableSessions(reFetch = false) {
      this.loaded.available = false
      if (!reFetch) {
        this.loaded.availableFetching = true
      } else {
        this.loaded.availableReFetching = true
      }
      return axios
        .get('/api/panel-sessions/upcoming')
        .then((response) => {
          let data = response.data.data.availablePanelSessions
          data = data.map(castDatesFromApi)
          this.availablePanelSessions = data
          this.loaded.available = true
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              errorToast('Fetch Sessions Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          this.loaded.availableFetching = false
          this.loaded.availableReFetching = false
        })
    },
    async fetchUserPanelSessionRange(start: Moment, end: Moment, reFetch = false) {
      this.loaded.scheduled = false
      if (!reFetch) {
        this.loaded.scheduledFetching = true
      } else {
        this.loaded.scheduledReFetching = true
      }
      return axios
        .get('/api/current-user/panel-sessions', {
          params: {
            start_date: start.toDate(),
            end_date: end.toDate()
          }
        })
        .then((response) => {
          let data = response.data.data.panelSessions
          this.panelSessionIcalFeed = response.data.data.panelSessionIcalFeed ?? null
          data = data.map(castDatesFromApi)
          this.loadScheduledPanelSessions(data)
          this.loadedScheduledPanelSessionRange = {
            earliest:
              this.loadedScheduledPanelSessionRange?.earliest &&
              start.isBefore(this.loadedScheduledPanelSessionRange?.earliest as Moment)
                ? start
                : this.loadedScheduledPanelSessionRange?.earliest || start,
            latest:
              this.loadedScheduledPanelSessionRange?.latest &&
              end.isAfter(this.loadedScheduledPanelSessionRange?.latest as Moment)
                ? end
                : this.loadedScheduledPanelSessionRange?.latest || end
          }
          this.loaded.scheduled = true
          return response
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              errorToast('Fetch User Sessions by Range Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          this.loaded.scheduledFetching = false
          this.loaded.scheduledReFetching = false
        })
    },
    async loadInitialScheduledPanelSessions(
      referenceDate: Moment = moment(),
      direction: 'backward' | 'forward' | null = null
    ): Promise<void> {
      await this.loadMoreScheduledPanelSessions(referenceDate, direction, false)
    },
    async loadMoreScheduledPanelSessions(
      referenceDate: Moment = moment(),
      direction: 'backward' | 'forward' | null = null,
      reFetch = false
    ): Promise<void> {
      if (
        this.loadedScheduledPanelSessionRange === null ||
        referenceDate < this.loadedScheduledPanelSessionRange?.earliest ||
        referenceDate > this.loadedScheduledPanelSessionRange.latest
      ) {
        const [startDate, endDate] = this.getDateRangeForPanelSessions(referenceDate, direction)
        await this.fetchUserPanelSessionRange(startDate, endDate, reFetch)
      }
    },
    getDateRangeForPanelSessions(
      referenceDate: Moment = moment(),
      direction: string | null = null
    ): Moment[] {
      const date = moment(referenceDate)
      let startDate: Moment, endDate: Moment
      const months = 2

      if (direction === 'backward') {
        startDate = date.clone().subtract(months, 'months').startOf('month')
        endDate = date.clone().add(1, 'month').startOf('month')
      } else if (direction === 'forward') {
        startDate = date.clone().startOf('month')
        endDate = date
          .clone()
          .add(months + 1, 'month')
          .startOf('month')
      } else {
        startDate = date.clone().subtract(months, 'months').startOf('month')
        endDate = date
          .clone()
          .add(months + 1, 'month')
          .startOf('month')
      }

      return [startDate, endDate]
    },
    loadScheduledPanelSessions(data: PanelSession[]) {
      data.forEach((newSession) => {
        const panelIndex = this.loadedScheduledPanelSessions.findIndex(
          (session) => session.id == newSession.id
        )
        if (panelIndex === -1) {
          const newIndex = this.loadedScheduledPanelSessions.findIndex(
            (session) => new Date(session.startTime) > new Date(newSession.startTime)
          )

          if (newIndex === -1) {
            this.loadedScheduledPanelSessions.push(newSession)
          } else {
            this.loadedScheduledPanelSessions.splice(newIndex, 0, newSession)
          }
        }
      })
    },
    async fetchUserPanelSessionHistory() {
      this.loaded.history = false
      this.loaded.historyFetching = true
      return axios
        .get('/api/current-user/panel-sessions/history')
        .then((response) => {
          let data = response.data.data.panelSessions
          data = data.map(castDatesFromApi)
          this.panelSessionsHistory = data
          this.loaded.history = true
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              errorToast('Fetch Session History Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          this.loaded.historyFetching = false
        })
    },
    async addPanelSession(panelSessionId: number) {
      this.loaded.scheduled = false
      this.loaded.scheduledReFetching = true
      this.loaded.available = false
      this.loaded.availableReFetching = true
      return axios
        .post('/api/current-user/panel-sessions/add', {
          panel_session_id: panelSessionId
        })
        .then(async (response) => {
          if (response.status === 200) {
            const panelSession = response.data.data.panelSession
            const [startDate, endDate] = this.getDateRangeForPanelSessions(
              moment(panelSession.startTime)
            )
            await Promise.all([
              this.fetchAvailableSessions(true),
              this.fetchUserPanelSessionRange(startDate, endDate, true)
            ])
            const message =
              'Your sensory session will begin ' +
              moment(response.data.data.panelSession.startTime).format(
                'dddd, MMMM Do [at] h:mm a'
              ) +
              '.'
            quickToast('Session Added to Calendar', message, 'success')
            return response
          }
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              if (error.response.data.errors.panel_session_id?.length > 1) {
                error.response.data.errors.panel_session_id.forEach((errorMessage: string) =>
                  errorToast('Add Session Error', errorMessage)
                )
              } else errorToast('Add Session Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          this.loaded.scheduled = true
          this.loaded.scheduledReFetching = false
          this.loaded.available = true
          this.loaded.availableReFetching = false
          if (error.response) return error.response
        })
    },
    async removePanelSession(panelSessionId: number) {
      this.loaded.scheduled = false
      this.loaded.scheduledReFetching = true
      this.loaded.available = false
      this.loaded.availableReFetching = true
      return axios
        .post('/api/current-user/panel-sessions/remove', {
          panel_session_id: panelSessionId
        })
        .then(async (response) => {
          if (response.status === 200) {
            const panelSession = response.data.data.panelSession
            const [startDate, endDate] = this.getDateRangeForPanelSessions(
              moment(panelSession.startTime)
            )
            await Promise.all([
              this.fetchAvailableSessions(true),
              this.fetchUserPanelSessionRange(startDate, endDate, true)
            ])
            quickToast(
              'Session Removed from Calendar',
              'You are no longer registered for this session.',
              'warning'
            )
          }
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              if (error.response.data.errors.panel_session_id?.length > 1) {
                error.response.data.errors.panel_session_id.forEach((errorMessage: string) =>
                  errorToast('Remove Session Error', errorMessage)
                )
              } else errorToast('Remove Session Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          this.loaded.scheduled = true
          this.loaded.scheduledReFetching = false
          this.loaded.available = true
          this.loaded.availableReFetching = false
          if (error.response) return error.response
        })
    },
    async switchPanelSession(panelSessionId: number) {
      this.loaded.scheduled = false
      this.loaded.scheduledReFetching = true
      this.loaded.available = false
      this.loaded.availableReFetching = true
      return axios
        .post('/api/current-user/panel-sessions/switch', {
          panel_session_id: panelSessionId
        })
        .then(async (response) => {
          if (response.status === 200) {
            const panelSession = response.data.data.panelSession
            const [startDate, endDate] = this.getDateRangeForPanelSessions(
              moment(panelSession.startTime)
            )
            await Promise.all([
              this.fetchAvailableSessions(),
              this.fetchUserPanelSessionRange(startDate, endDate)
            ])
            const message =
              'Your sensory session will now begin ' +
              moment(panelSession.startTime).format('dddd, MMMM Do [at] h:mm a') +
              '.'
            quickToast('Session Swapped', message, 'info')
          }
          return response
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              if (error.response.data.errors.panel_session_id?.length > 1) {
                error.response.data.errors.panel_session_id.forEach((errorMessage: string) =>
                  errorToast('Switch Session Error', errorMessage)
                )
              } else errorToast('Switch Session Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message) {
                errorToast(error.message, error.response.data.message)
              } else errorToast(error.name, error.message)
              console.error(error)
          }
          this.loaded.scheduled = true
          this.loaded.scheduledReFetching = false
          this.loaded.available = true
          this.loaded.availableReFetching = false
          if (error.response) return error.response
        })
    }
  },
  getters: {
    panels: (state): Panel[] => {
      if (state.availablePanelSessions) {
        const out: Panel[] = []
        state.availablePanelSessions.forEach((panelSession) => {
          const panelIndex = out.findIndex((panel) => panel.id == panelSession.panelId)
          if (panelIndex === -1) {
            out.push({
              id: panelSession.panelId,
              name: panelSession.panelName,
              isTakeAway: panelSession.isTakeAway,
              panelSessions: [panelSession]
            })
          } else {
            out[panelIndex].panelSessions?.push(panelSession)
          }
        })
        return out
      }
      return []
    }
  }
})

export { usePanelSessionStore, castDatesFromApi }
