<template>
  <header class="flex items-center justify-between px-6 py-4 lg:flex-none">
    <h2>
      <y-icon
        :icon="copyCompleted ? 'calendar-check' : 'calendar'"
        v-if="icalFeedUrl != ''"
        @click="copyIcalUrl"
      ></y-icon>
      Your Schedule
    </h2>
    <div class="flex items-center user-schedule-nav">
      <div class="relative flex items-center">
        <button type="button" class="flex h-9 w-12 items-center justify-center" @click="prevMonth">
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <h3 class="user-schedule-nav__current-month" @click="thisMonth">
          {{ currentMonth }}
        </h3>
        <button type="button" class="flex h-9 w-12 items-center justify-center" @click="nextMonth">
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { scheduleFocusDate } from '@/components/UserSchedule/userScheduleComposables'
import { YIcon } from '@yakimachief/ych-ts-component-library'

import { computed, ref } from 'vue'
import moment from 'moment'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import { useLoadingStore } from '@/stores/LoadingStore'

export interface Props {
  icalFeedUrl: string
}

const panelSessionStore = usePanelSessionStore()
const loadingStore = useLoadingStore()

const props = withDefaults(defineProps<Props>(), {
  icalFeedUrl: ''
})

const copyCompleted = ref(false)
const currentMonth = computed(() => {
  return scheduleFocusDate.value.format('MMMM YYYY')
})

function copyIcalUrl() {
  window.navigator.clipboard.writeText(props.icalFeedUrl)
  copyCompleted.value = true

  setTimeout(function () {
    copyCompleted.value = false
  }, 1500)
}

const nextMonth = () => {
  if (!loadingStore.isLoadingCalendar) {
    scheduleFocusDate.value = scheduleFocusDate.value.clone().add(1, 'month').startOf('month')
    panelSessionStore.loadMoreScheduledPanelSessions(scheduleFocusDate.value, 'forward')
  }
}
const prevMonth = () => {
  if (!loadingStore.isLoadingCalendar) {
    scheduleFocusDate.value = scheduleFocusDate.value.clone().subtract(1, 'month').startOf('month')
    panelSessionStore.loadMoreScheduledPanelSessions(scheduleFocusDate.value, 'backward')
  }
}

const thisMonth = () => {
  scheduleFocusDate.value = moment().startOf('month')
}
</script>

<style scoped lang="scss">
.user-schedule-nav {
  text-transform: uppercase;

  &__current-month {
    text-align: center;
    width: 145px;
  }
}
</style>
