<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import UpcomingSessions from '../components/UpcomingSessions'
import UserSchedule from '../components/UserSchedule'
import { useUserStore } from '@/stores/UserStore'
import AdminQuickLinks from '../components/AdminQuickLinks.vue'
import StatsAndMetrics from '../components/StatsAndMetrics'
import { useLoadingStore } from '@/stores/LoadingStore'
import { YSpinner } from '@yakimachief/ych-ts-component-library'

const loadingStore = useLoadingStore()
loadingStore.setInitialFetchTime()
const isLoading = computed(() => loadingStore.isLoading)

const userStore = useUserStore()
</script>

<template>
  <div v-show="!isLoading">
    <div
      class="limit-width min-content-height grid grid-cols-1 lg:grid-cols-2 gap-8 pt-4 pb-6 px-4 sm:py-8 sm:px-12 mx-auto items-start"
    >
      <div class="grid gap-8">
        <div class="card">
          <UpcomingSessions />
        </div>
        <div class="card">
          <UserSchedule />
        </div>
      </div>
      <div class="grid gap-8">
        <div class="card">
          <StatsAndMetrics />
        </div>
        <div class="card" v-if="userStore.isCurrentAdmin">
          <AdminQuickLinks />
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="loading grid grid-cols-1 content-center">
    <div class="flex justify-center">
      <YSpinner
        width="16rem"
        loading-text="Loading"
        :text-animated="true"
        :display-ellipsis="false"
        text-color="#ffffff"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  position: absolute;
  z-index: 1000;
  top: 0px;
  height: 100vh;
  width: 100%;
  background: 0% 0% / contain rgb(0, 43, 73);
}
</style>
