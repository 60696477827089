<template>
  <div class="relative">
    <div :class="[loadingStore.isLoadingAvailableSessions ? 'opacity-20' : '']">
      <UpcomingSessionsTitle />
      <div class="sessions">
        <div class="sessions__tabs">
          <a
            :class="{
              sessions__tabs__tab: true,
              'sessions__tabs__tab--active': currentTab === 'by-panel',
              h4: true
            }"
            href="#"
            @click.prevent="currentTab = 'by-panel'"
            >By Panel</a
          >
          <a
            :class="{
              sessions__tabs__tab: true,
              'sessions__tabs__tab--active': currentTab === 'by-session',
              h4: true
            }"
            href="#"
            @click.prevent="currentTab = 'by-session'"
            >By Session</a
          >
        </div>
        <div class="sessions__tabs__content" v-if="currentTab === 'by-session'">
          <UpcomingSessionsFilters />
          <UpcomingSessionsList />
        </div>
        <div class="sessions__tabs__content" v-if="currentTab === 'by-panel'">
          <GroupByPanelList />
        </div>
      </div>
    </div>
    <CardSpinner v-if="loadingStore.isLoadingAvailableSessions" />
  </div>
</template>

<script setup lang="ts">
import CardSpinner from '@/components/CardSpinner.vue'

import { sessionsFilterSettings } from '@/components/UpcomingSessions/upcomingSessionsComposables'
import type { PanelSessionsSortField } from '@/components/UpcomingSessions/upcomingSessionsComposables'

import UpcomingSessionsFilters from '@/components/UpcomingSessions/UpcomingSessionsFilters.vue'
import UpcomingSessionsList from '@/components/UpcomingSessions/UpcomingSessionsList.vue'
import UpcomingSessionsTitle from '@/components/UpcomingSessions/UpcomingSessionsTitle.vue'
import GroupByPanelList from '@/components/UpcomingSessions/GroupByPanelList.vue'
import { ref } from 'vue'

import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import { useLoadingStore } from '@/stores/LoadingStore'

const panelSessionStore = usePanelSessionStore()
panelSessionStore.fetchAvailableSessions()

const loadingStore = useLoadingStore()

export interface Props {
  sessionSortByDefault?: PanelSessionsSortField
  sessionSortAscend?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  sessionSortByDefault: 'date-time',
  sessionSortAscend: true
})

const currentTab = ref('by-panel')

sessionsFilterSettings.value.currentSortField = props.sessionSortByDefault
if (props.sessionSortAscend) {
  sessionsFilterSettings.value.currentSortDirection = 'asc'
} else {
  sessionsFilterSettings.value.currentSortDirection = 'desc'
}
</script>

<style lang="scss" scoped>
.sessions {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__tabs {
    display: flex;
    padding-left: 0.5rem;
    z-index: 0;
    &__tab {
      padding: 0.5rem 0.75rem;
      text-decoration: none;
      background: var(--ych-white);
      color: var(--secondary-brand-color);
      border-radius: var(--y-radius-lg) var(--y-radius-lg) 0 0;
      margin-right: -0.25rem;
      &--active {
        background: var(--secondary-brand-color);
        color: var(--primary-accent-color);
        pointer-events: none;
        z-index: 1;
      }
      &:hover {
        background: var(--ych-grey-100);
        color: var(--secondary-brand-color);
        box-shadow: var(--y-shadow-grey-sm);
        z-index: 2;
      }
    }
    &__content {
      border: 1px solid var(--ych-grey-400);
      border-radius: var(--y-radius-md);
      padding: 0.5rem 0.5rem 0 0.5rem;
      background: var(--ych-white);
      z-index: 1;
    }
  }
}
</style>
