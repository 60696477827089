<script setup lang="ts">
import { Bar } from 'vue-chartjs'
import merge from 'ts-deepmerge'

import { Chart, registerables } from 'chart.js'
import type { ChartData, ChartOptions, Plugin } from 'chart.js'
import { useOrganizationStore } from '@/stores/OrganizationStore'

Chart.register(...registerables)

export interface Props {
  data: ChartData<'bar'>
  plugins?: Plugin<'bar'>[] | null
  options?: Object | null
  height?: string
  stacked: boolean
  displayLegend?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  options: null,
  displayLegend: false,
  height: '300px',
  stacked: true
})

function getChartPlugins(): Plugin<'bar'>[] {
  return props.plugins ?? []
}

function getChartOptions(): ChartOptions<'bar'> {
  const organizationStore = useOrganizationStore()
  let defaultOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 1,
    categoryPercentage: 0.8,

    layout: {
      padding: {
        left: 10
      },
      autoPadding: false
    },
    scales: {
      y: {
        stacked: props.stacked,
        ticks: {
          color: organizationStore.getCurrentOrganizationTheme().secondaryBrandColor,
          crossAlign: 'far',
          autoSkip: false,
          padding: 0,
          font: {
            weight: 'bold',
            family: 'Roboto Condensed',
            size: 14
          }
        }
      },
      x: {
        min: 0,
        max: 1,
        ticks: {
          format: {
            style: 'percent'
          },
          stepSize: 100
        }
      }
    },
    plugins: {
      legend: {
        display: props.displayLegend
      },
      datalabels: {
        color: '#002B49',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        borderRadius: 3,
        anchor: 'end',
        align: (context) => {
          const index = context.dataIndex
          const data = context.dataset.data
          const value = data[index] ?? 0
          if (typeof value === 'number' && value * 100 > 90) {
            return 'left'
          } else {
            return 'right'
          }
        },
        font: { weight: 'bold' },
        padding: { top: 1, bottom: 1, left: 2, right: 2 },
        formatter: function (value, context) {
          return Math.round(value * 100) + '%'
        }
      }
    }
  } as ChartOptions<'bar'>

  return merge(defaultOptions, props.options ?? {}) as ChartOptions<'bar'>
}
</script>

<template>
  <Bar
    v-if="data"
    :style="{ maxHeight: height }"
    id="sensory-chart"
    :data="props.data"
    :options="getChartOptions()"
    :plugins="getChartPlugins()"
  ></Bar>
</template>

<style scoped lang="scss"></style>
